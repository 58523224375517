<template>
  <div id="app">

    <div class="mine_t clear">
      <img class="bg_mine" src="../../../src/assets/images/bg_mine.jpg" alt="">
      <div class="mine_avatar" :style="{backgroundImage:`url(${img})`}"></div>
      <div class="mine_details">
                 <span>
                     {{
                     customerUser != null ? customerUser.businessCustomerUser.name : ''
                   }} <font>{{ customerUser != null ? customerUser.businessCustomerUser.structure.name : '' }}</font>
                 </span>
        <label>{{ customerUser != null ? customerUser.businessCustomerUser.business.name : '' }}</label>
      </div>
<!--      <div class="mine_change iconfont">-->
<!--        &#xeb4b; 切换用户-->
<!--      </div>-->
    </div>

    <div class="tuition_tab clear">
      <div class="tuition_tab_l">
        <ul>
          <li :class="stateStyle0" @click="changeState(0)">未开始</li>
          <li :class="stateStyle1" @click="changeState(1)">进行中</li>
          <li :class="stateStyle2" @click="changeState(2)">已截止</li>
        </ul>
      </div>
      <div class="tuition_tab_r">
        <span>仅显示未缴费</span>
        <van-switch
            v-model="onlyShowWaiting"
            size="14px"
            active-color="#34ac40"
            @change="changeOnlyShowWaiting"
        />
      </div>
    </div>

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchDataList">
      <div v-for="(option, index) in dataList" :key="index">
        <div class="tuition_list" @click="routeToDetail(option)">
          <div class="tuition_list_t">
            <span>{{ option.miscellaneousFees.name }}</span>
            <label>开始时间：{{ option.miscellaneousFees.startTime | dayjs }}</label>
            <label>截止时间：{{ option.miscellaneousFees.endTime | dayjs }}</label>
          </div>
          <div class="tuition_list_b">
            <ul class="clear">
              <li :class="handleStateClass(option.miscellaneousFees.state)">
                {{ handleState(option.miscellaneousFees.state) }}
              </li>
              <li :class="handlePaymentStateClass(option.paymentState)"> {{
                  handlePaymentState(option.paymentState)
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'

export default {
  name: "TuitionAndFeesList",
  components: {},
  data() {
    return {
      customerUser: null,
      img: require('../../../src/assets/images/avatar3.png'),
      onlyShowWaiting: true,
      loading: false,
      finished: false,
      page: 0,
      size: 20,
      dataList: [],
      stateStyle0: 'unselected',
      stateStyle1: 'selected',
      stateStyle2: 'unselected'
    };
  },
  mounted() {
    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);
    if (this.customerUser === null) {
      this.$router.replace({path: '/index'})
      return;
    }
    this.fetchDataList();
  },
  methods: {
    changeState(index) {
      if (index === 0) {
        if (this.stateStyle0 === 'selected') {
          this.stateStyle0 = "unselected";
        } else {
          this.stateStyle0 = "selected";
        }

      } else if (index === 1) {
        if (this.stateStyle1 === 'selected') {
          this.stateStyle1 = "unselected";
        } else {
          this.stateStyle1 = "selected";
        }
      } else if (index === 2) {
        if (this.stateStyle2 === 'selected') {
          this.stateStyle2 = "unselected";
        } else {
          this.stateStyle2 = "selected";
        }
      }
      this.fetchDataList(true);
    },
    changeOnlyShowWaiting() {
      this.fetchDataList(true);
    },
    fetchDataList(isRefresh) {
      this.loading = true;
      if (isRefresh) {
        this.page = 0;
        this.dataList = [];
        this.finished = false;
      }

      let paymentState = "";
      if (this.onlyShowWaiting) {
        paymentState = 'WAITING';
      }

      let stateList = [];
      if (this.stateStyle0 === 'selected') {
        stateList = stateList.concat('WAIT_START');
      }
      if (this.stateStyle1 === 'selected') {
        stateList = stateList.concat('ON_GOING');
      }
      if (this.stateStyle2 === 'selected') {
        stateList = stateList.concat('STOP');
      }

      // 全取消后也显示全部内容
      if (stateList.length === 0) {
        stateList = ['WAIT_START', 'ON_GOING', 'STOP'];
      }

      const params = {
        page: this.page,
        size: this.size,
        filters: [
          {
            "name": "businessCustomerUserId",
            "op":"=",
            "value": this.customerUser.businessCustomerUserId
          },
          {
            "name": "paymentState",
            "op":"=",
            "value": paymentState
          },
          {
            "name": "miscellaneousFees.state",
            "op":"in",
            "value": stateList
          },
        ]
      }

      post('api/business/v1/miscellaneous/fees/consumers/search', params, true)
          .then((res) => {
            if (isRefresh) {
              this.dataList = [];
              console.log("0")
            }
            this.page = this.page + 1;

            this.dataList = this.dataList.concat(res.data.rows);
            console.log("concat")
            // 加载状态结束
            this.loading = false;

            // 数据全部加载完成
            if (this.dataList.length >= res.data.total) {
              this.finished = true;
            }

          })
          .catch((err) => {
          })
    },
    routeToDetail(option) {
      this.$router.push({path: '/tuition/tuitionAndFeesDetail', query: {id: option.miscellaneousFees.id}})
    },
    handleState(state) {
      switch (state) {
        case "WAIT_START":
          return '未开始';
        case "ON_GOING":
          return '进行中';
        case "STOP":
          return '已截止';
      }
    },
    handleStateClass(state) {
      switch (state) {
        case "WAIT_START":
          return 'iconfont icon-qingjia1 dark';
        case "ON_GOING":
          return 'iconfont icon-qingjia1 blue';
        case "STOP":
          return 'iconfont icon-qingjia1 gray';
      }
    },
    handlePaymentState(state) {
      switch (state) {
        case "COMPLETED":
          return '已缴费';
        case "WAITING":
          return '未缴费';
        case "REFUND":
          return '已退款';
      }
    },
    handlePaymentStateClass(state) {
      switch (state) {
        case "COMPLETED":
          return 'iconfont icon-qian3 blue';
        case "WAITING":
          return 'iconfont icon-qian3 orange';
        case "REFUND":
          return 'iconfont icon-qian3 red';
      }
    },
  }
};
</script>

<style scoped>
::v-deep body {
  background: #ffffff !important;
}

#app {
  height: 100vh;
  background: #fff;
}

.mine_t {
  height: 28vw;
  overflow: hidden;
  position: relative;
}

.mine_t::before {
  content: '';
  width: 100%;
  height: 3vw;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tuition_tab {
  padding: 0 3vw;
  margin-bottom: 5vw;
}

.tuition_tab_l {
  float: left;
}

.tuition_tab_l ul li {
  height: 7vw;
  line-height: 7vw;
  padding: 0 2vw;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  float: left;
  margin-right: 3vw;
}

.tuition_tab_l ul li.unselected {

}

.tuition_tab_l ul li.selected {
  background: #34ac40;
  border: 1px solid #34ac40;
  color: #fff;
}

.tuition_tab_r {
  float: right;
  height: 7vw;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: right;
  flex-direction: row;
  flex-wrap: wrap;
}

.tuition_tab_r span {
  margin-right: 2vw;
}

.tuition_list {
  width: 94vw;
  margin: 0 auto;
  margin-bottom: 5vw;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3vw;
}

.tuition_list_t {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 4vw;
}

.tuition_list_t span {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.tuition_list_t label {
  display: block;
  color: #999;
  margin-top: 4px;
}

.tuition_list_b {
  padding-top: 3vw;
}

.tuition_list_b ul li {
  float: left;
  margin-right: 3vw;
  padding-right: 3vw;
  position: relative;
}

.tuition_list_b ul li:first-child::after {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #e5e5e5;
}

.tuition_list_b ul li.black {
  color: #4B4B4B;
}

.tuition_list_b ul li.blue {
  color: #3485ff;
}

.tuition_list_b ul li.gray {
  color: #999999;
}

.tuition_list_b ul li.orange {
  color: #ff6034;
}
</style>